<template>
<!-- eslint-disable max-len -->
  <exterior-page-content-container>
    <p class="text-2xl font-bold text-center text-primary">
      Terms and Conditions
    </p>
    <div class="md:w-1/2">
      <div
        v-for="section in sections" :key="section"
        class="mb-5 text-justify "
      >
        <p
          v-for="(paragraph, index) in section.split('\n')" :key="paragraph"
          :class="[index == 0 ? 'font-bold mb-2 text-primary text-lg' : 'text-content']"
        >
          {{paragraph}}
        </p>
      </div>
    </div>
    <button-large
      @click="agree"
      color="primary"
      class="mt-5"
    >
      Register an Account
    </button-large>
  </exterior-page-content-container>
</template>

<script>
import ExteriorPageContentContainer from '@/components/shared/containers/ExteriorPageContentContainer.vue';

import PageTextContent from '@/assets/text/termsAndConditions.txt';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'TermsAndConditions',
  components: {
    ExteriorPageContentContainer,
    ButtonLarge,
  },
  data() {
    return {
      sections: PageTextContent.split('\n\n'),
    };
  },
  methods: {
    agree() {
      this.$router.push({ name: 'Register' });
    },
  },
};
</script>
